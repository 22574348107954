import React from 'react'
import { Link } from 'gatsby'

import SEO from '../components/scaffolding/seo'

const ContactUsPage = () => {
  return (
    <>
      <SEO title="Contact Us"/>
      <section className="content content-404">
        <div className="wrapper">
          <h1>
            MUZE
          </h1>
          <p>
            {`reach us at info@muze.nyc`}
          </p>
          <p>
          <Link to="/">back to homepage</Link>
          </p>
        </div>
      </section>
    </>
  )
}

export default ContactUsPage
